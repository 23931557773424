<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col flex items-center  flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center"
    >
      <h1
        class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color title"
        v-html="maintenanceMessage"
      ></h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maintenanceMessage: ""
    };
  },
  created() {
    this.$appInsights.trackPageView({
      name: "page-error-503",
      url: window.location.href
    });

    var portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    this.maintenanceMessage = portalConfig.maintenanceMessage;
  }
};
</script>

<style lang="scss">
.title {
  color: white;
}
</style>
